import { blue } from "@ant-design/colors"
import { Breadcrumb, Button, Card, DatePicker, Icon, Input, Table } from "antd"
import { gql } from "apollo-boost"
import { Link, navigate } from "gatsby"
import moment from "moment"
import qs from "query-string"
import React from "react"
import { Query } from "react-apollo"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const { Column } = Table
const { RangePicker } = DatePicker

const STOCK_REPORT = gql`
  query stockReport($endDate: date, $name: String, $startDate: date) {
    mbt_stock_report(
      order_by: { date: asc, item: { name: asc } }
      where: {
        date: { _gte: $startDate, _lte: $endDate }
        item: { name: { _ilike: $name } }
      }
    ) {
      date
      item {
        id
        name
        unit
      }
      in
      out
      cumulative
    }
  }
`

const quantityRenderer = (text, record) =>
  text === 0 ? "-" : `${text} ${record.item.unit}`

export default ({ location }) => {
  const defaultDate = [moment().startOf("month"), moment().endOf("month")]
  const { date, name } = qs.parse(location.search)
  let startDate, endDate
  if (Array.isArray(date)) {
    startDate = moment(date[0], "L").startOf("day")
    endDate = moment(date[1], "L").endOf("day")
  }
  let filterInput = null
  return (
    <Layout>
      <SEO title="Stock Report" />
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to="/">
            <Icon type="home" />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Stock Report</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <div style={{ marginBottom: 16 }}>
          <RangePicker
            defaultValue={[startDate, endDate]}
            format="L"
            placeholder={defaultDate.map(date => date.format("L"))}
            onChange={value =>
              navigate(
                `/report/stock/?${qs.stringify({
                  date: value.map(date => date.format("L")),
                  name,
                })}`
              )
            }
          />
        </div>
        <Query
          query={STOCK_REPORT}
          variables={{
            endDate: (endDate || defaultDate[1]).toISOString(true),
            startDate: (startDate || defaultDate[0]).toISOString(true),
            name: name && `%${name.replace(/([_%])/g, "\\$1")}%`,
          }}
        >
          {({ loading, data = {} }) => (
            <Table
              bordered
              dataSource={data.mbt_stock_report}
              loading={loading}
              pagination={false}
              rowKey={record =>
                `${record.in ? "in" : "out"}-${record.date}-${record.item.id}`
              }
              size="middle"
              onFilterDropdownVisibleChange={visible => {
                if (visible) {
                  setTimeout(() => filterInput.select())
                }
              }}
              onChange={(pagination, filters) =>
                navigate(
                  `/report/stock/?${qs.stringify({
                    date: startDate &&
                      endDate && [startDate.format("L"), endDate.format("L")],
                    name: filters["item.name"],
                  })}`
                )
              }
            >
              <Column
                dataIndex="date"
                title="Date"
                render={text => moment(text).format("L")}
              />
              <Column
                dataIndex="item.name"
                title="Item"
                filterDropdown={({
                  selectedKeys,
                  setSelectedKeys,
                  clearFilters,
                  confirm,
                }) => (
                  <div style={{ padding: 8 }}>
                    <Input
                      ref={node => {
                        filterInput = node
                      }}
                      placeholder="Search"
                      value={selectedKeys[0]}
                      onChange={e =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                      }
                      onPressEnter={() => confirm()}
                      style={{
                        width: 188,
                        marginBottom: 8,
                        display: "block",
                      }}
                    />
                    <Button
                      type="primary"
                      onClick={() => confirm()}
                      icon="search"
                      size="small"
                      style={{ width: 90, marginRight: 8 }}
                    >
                      Search
                    </Button>
                    <Button
                      onClick={() => clearFilters()}
                      size="small"
                      style={{ width: 90 }}
                    >
                      Reset
                    </Button>
                  </div>
                )}
                filterIcon={filtered => (
                  <Icon
                    type="search"
                    style={{ color: filtered ? blue.primary : undefined }}
                  />
                )}
                filteredValue={name ? [name] : []}
              />
              <Column dataIndex="in" title="In" render={quantityRenderer} />
              <Column dataIndex="out" title="Out" render={quantityRenderer} />
              <Column
                dataIndex="cumulative"
                title="Running Stock"
                render={quantityRenderer}
              />
            </Table>
          )}
        </Query>
      </Card>
    </Layout>
  )
}
